<template>
  <ui-button
    variant="primary-light"
    icon-left="notifications_none"
    class="text-2xl"
    rounded="full"
    :active="active"
    v-bind="btnAttrs"
  >
    <template #icon-right>
      <span
        v-if="isNotifications"
        :class="isMobile ? 'top-1.5 right-1.5 w-1 h-1' : 'top-2 right-2 w-1.5 h-1.5'"
        class="rounded-full absolute bg-primary group-[&.active]:bg-current group-hover:bg-current group-focus:bg-current"
      />
    </template>
  </ui-button>
</template>

<script setup lang="ts">
  type Props = {
    isMobile?: boolean;
    active?: boolean;
  };
  const props = defineProps<Props>();
  const { user } = useAuthUser();
  const isNotifications = computed(() => user.value?.unread_notifications_exists);

  const btnAttrs = computed(() => {
    if (props.isMobile) {
      return {
        size: 'sm-icon',
        to: { name: 'dashboard-notifications' }
      };
    }
    return {
      size: 'lg-icon'
    };
  });
</script>
